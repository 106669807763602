<template>
<div >
  <div id="fixedHeder">
  <div class="container head-nav hide-mobile">
    <div class="margin-auto width-100">
      <b-navbar toggleable="lg" type="dark">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-brand to="/" class="margin-auto">
          <img :src="$store.state.company_profile.logo" alt="Logo" />
        </b-navbar-brand>
      
        <b-collapse id="nav-collapse" is-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto sub-nav" right>
            <b-nav-item to="/" exact>
              <b-navbar-brand>
                <div @mouseover="onLeave()">Beranda</div> 
              </b-navbar-brand>
            </b-nav-item>

            <template v-for="(m, i) in menu">
              <template v-if="m.childs.length == 0">
                <!-- menu tunggal -->
                <template v-if="m.cta == 'page'">
                  <b-nav-item @click="redirectToPost(m.page)" exact :key="i">
                    <b-navbar-brand>
                      <div @mouseover="onLeave()">{{ m.name }}</div> 
                    </b-navbar-brand>
                  </b-nav-item>
                </template>
                <template v-else>
                  <b-nav-item @click="redirectToPost(m.post)" exact :key="i">
                    <b-navbar-brand>
                      <div @mouseover="onLeave()">{{ m.name }}</div> 
                    </b-navbar-brand>
                  </b-nav-item>
                </template> 
                <!-- end menu tunggal -->
              </template>
              <template v-else>
                <!-- menu beranak -->
                  <b-nav-item exact :key="i">
                    <b-navbar-brand>
                      <div @mouseover="onOver(m.id)" >{{ m.name }}</div>
                    </b-navbar-brand>
                    <template v-if="NavMega === m.id">
                      <div class="mega-menu" @mouseleave="onLeave()">
                        <div class="row">
                          <div class="col-md-4 container-for-mega">
                            <ul class="for-navigation">
                              <li v-for="(chil, y) in m.childs" :key="y">
                                <template v-if="chil.childs.length">
                                  <a v-bind:class="{ active: profilState == chil.id }"
                                    @click.prevent="checkMenuPub(chil.id)"
                                    @mouseover="subMegamenu = chil.childs"
                                    href="#"
                                    >
                                    {{ chil.name }} 
                                    <i class="feather icon-chevron-right"></i>
                                  </a>
                                </template>
                                <template v-else>
                                  <template v-if="chil.name.toLowerCase() == 'pejabat'">
                                    <router-link to="/pejabat">{{ chil.name }}</router-link>
                                  </template>
                                  <template v-else>
                                    <template v-if="chil.cta == 'page'">
                                      <a @click="redirectToPage(chil.page)">{{ chil.name }}</a>
                                    </template>
                                    <template v-else>
                                      <a @click="redirectToPost(chil.post)">{{ chil.name }}</a>
                                    </template> 
                                  </template>
                                </template>
                              </li>
                            </ul>
                          </div>
                          <div class="col-md-8" v-if="subMegamenu.length || forSubMega.length">
                            <div class="last-section container-for-content scrolling">
                              <div class="row">
                                <div class="col-md-6" v-if="subMegamenu.length">
                                  <div class="for-nav" v-for="(items, index)  in subMegamenu" :key="index">
                                    <template v-if="items.childs.length">
                                      <span class="d-block" @click="forSubMega = items.childs">
                                        {{ items.name }}
                                        <i class="feather icon-chevron-right"></i>
                                      </span>
                                    </template>
                                    <template v-else>
                                      <template v-if="items.cta == 'page'">
                                        <span class="d-block" @click="redirectToPage(items.page)">{{ items.name }}</span>
                                      </template>
                                      <template v-else>
                                        <span class="d-block" @click="redirectToPost(items.post)">{{ items.name }}</span>
                                      </template>  
                                    </template>
                                  </div>
                                </div>
                                <div class="col-md-6" v-if="forSubMega.length">
                                  <div class="for-nav" v-for="(sub, i ) in forSubMega" :key="i">
                                    <span class="d-block">{{ sub.name }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </b-nav-item>
                <!-- end menu beranak -->
              </template>
            </template>

            <b-nav-item exact>
              <b-navbar-brand> <div @mouseover="onOver('infoPublik')">Informasi</div></b-navbar-brand>
              <template v-if="NavMega === 'infoPublik'">
                <div class="mega-menu"   @mouseleave="onLeave()">
                  <div class="row">
                    <div class="col-md-3 container-for-mega">
                      <ul class="for-navigation">
                        <li>
                          <a
                            @click.prevent="checkMenuInfoFunc(1)"
                            href="#"
                            v-bind:class="{ active: checkMenuInfo == 1 }"
                          >
                            Daftar Informasi Publik
                            <i class="feather icon-chevron-right"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            @click.prevent="checkMenuInfoFunc(2)"
                            href="#"
                            v-bind:class="{ active: checkMenuInfo == 2 }"
                          >
                            Daftar Informasi Penting
                            <i class="feather icon-chevron-right"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            @click.prevent="checkMenuInfoFunc(3)"
                            href="#"
                            v-bind:class="{ active: checkMenuInfo == 3 }"
                          >
                            Regulasi
                            <i class="feather icon-chevron-right"></i>
                          </a>
                        </li>
                         <li>
                          <a
                            @click.prevent="goTo('http://olap.halal.go.id/public/dashboard/2e2c7f22-34c1-4a3b-ad71-6d2b2775c91a')"
                            href="#"
                          >
                            Data Sertifikasi Halal
                            <!-- <i class="feather icon-chevron-right"></i> -->
                          </a>
                        </li>
                        <li v-for="(m, i) in menuInfoPublic.childs" :key="i">
                          <template v-if="m.id != 101">
                            <template v-if="m.cta == 'page'">
                              <a @click="redirectToPage(m.page)">{{ m.name }}</a>
                            </template>
                            <template v-else>
                              <a @click="redirectToPost(m.post)">{{ m.name }}</a>
                            </template>   
                          </template> 
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-8">
                      <template v-if="checkMenuInfo == 1">
                        <div
                          class="last-section container-for-content height-auto"
                        >
                          <div class="row">
                            <div
                              class="col-md-12 pb-2 for-nav"
                              v-for="(info, index) in publicInfo"
                              :key="index"
                              @click="redirectTo(info)"
                            >
                              <span class="d-block">{{ info.title }}</span>
                            </div>
                          </div>
                          <div class="col-md-12 text-center mt-2">
                            <router-link to="/public-information">
                              <span
                                class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer"
                              >
                                Lihat Lainnya
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </template>
                      <template v-if="checkMenuInfo == 2">
                        <div
                          class="last-section container-for-content height-auto"
                        >
                          <div class="row">
                            <div
                              class="col-md-12 pb-2 for-nav"
                              v-for="(info, index) in importantInfo"
                              :key="index"
                              @click="redirectTo(info)"
                            >
                              <span class="d-block">{{ info.title }}</span>
                            </div>
                          </div>
                          <div class="col-md-12 text-center mt-2">
                            <router-link to="/important-information">
                              <span
                                class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer"
                              >
                                Lihat Lainnya
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </template>
                      <template v-if="checkMenuInfo == 3">
                        <div
                          class="last-section container-for-content height-auto"
                        >
                          <div class="row">
                            <div
                              class="col-md-12 pb-2 for-nav"
                              v-for="(info, index) in regulationInfo"
                              :key="index"
                              @click="redirectTo(info)"
                            >
                              <span class="d-block">{{ info.title }}</span>
                            </div>
                          </div>
                          <div class="col-md-12 text-center mt-2">
                            <router-link to="/regulation-information">
                              <span
                                class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer"
                              >
                                Lihat Lainnya
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </template>
                      <!-- <template v-if="checkMenuInfo == 3">
                        <div class="last-section container-for-content scrolling">
                          <div class="row">
                            <div class="col-md-6" v-if="menuRegulasi.length">
                              <div class="for-nav " v-for="(items, index)  in menuRegulasi" :key="index">
                                <template v-if="items.childs.length">
                                  <span class="d-block" @click="forSubMegaRegulasi = items.childs">
                                    {{ items.name }}
                                    <i class="feather icon-chevron-right"></i>
                                  </span>
                                </template>
                                <template v-else>
                                  <template v-if="items.cta == 'page'">
                                    <span class="d-block" @click="redirectToPage(items.page)">{{ items.name }}</span>
                                  </template>
                                  <template v-else>
                                    <span class="d-block" @click="redirectToPost(items.post)">{{ items.name }}</span>
                                  </template>  
                                </template>
                              </div>
                            </div>
                            <div class="col-md-6" v-if="forSubMegaRegulasi.length">
                              <div class="for-nav" v-for="(sub, i ) in forSubMegaRegulasi" :key="i">
                                <span class="d-block">{{ sub.name }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template> -->
                    </div>
                  </div>
                </div>
              </template>
            </b-nav-item>

            <b-nav-item exact>
              <b-navbar-brand @click.prevent="checkMenuPub(1)" >
                <div  @mouseover="onOver('publikasi')">Publikasi</div>
              </b-navbar-brand>
              <template v-if="NavMega === 'publikasi'">
                <div class="mega-menu" @mouseleave="onLeave()">
                  <div class="row">
                    <div class="col-md-3 container-for-mega">
                      <ul class="for-navigation">
                        <li>
                          <a
                            @click.prevent="checkMenuPub(2)"
                            href="#"
                            v-bind:class="{ active: publikasiState == 2 }"
                            >Galeri <i class="feather icon-chevron-right"></i
                          ></a>
                        </li>
                        <li>
                          <a
                            href="#"
                            @click.prevent="checkMenuPub(3)"
                            v-bind:class="{ active: publikasiState == 3 }"
                            >Berita <i class="feather icon-chevron-right"></i
                          ></a>
                        </li>
                        <li>
                          <router-link to="/article"
                            >Artikel Dan Opini</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/announcement"
                            >Pengumuman</router-link
                          >
                        </li>
                        <li>
                          <router-link to="/calendar">Kalender</router-link>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-8">
                      <template v-if="publikasiState === 2">
                        <div class="last-section container-for-content">
                          <ul class="galery-header">
                            <li
                              v-for="(gallery, index) in galleries"
                              :key="index"
                              @click="showedGallery = index"
                            >
                              <template v-if="gallery.thumb !== undefined">
                                <img
                                  class="for-video-thumb for-head"
                                  src="@/assets/images/compro/icon/play-btn.svg"
                                  alt=""
                                />
                                <img :src="gallery.thumb" alt="" />
                              </template>
                              <template v-else>
                                <img :src="gallery.src" alt="" />
                              </template>
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-12 text-center">
                          <router-link to="/gallery">
                            <span
                              class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer"
                            >
                              Lihat Lainnya
                            </span>
                          </router-link>
                        </div>
                      </template>
                      <template v-if="publikasiState === 3">
                        <div class="row">
                          <template v-for="(berita, index) in beritaTerbaru">
                            <div class="col-md-6 news-for-mega" :key="index">
                              <router-link :to="`/berita/${berita.slug}`">
                                <template v-if="berita.banner">
                                  <img :src="berita.banner" alt="Berita" />
                                </template>
                                <template v-else>
                                  <img
                                    src="@/assets/images/compro/misc/default-image.jpg"
                                    alt=""
                                  />
                                </template>
                                <h2 class="elipsis-4-char ln-c-5">
                                  {{ berita.title }}
                                </h2>
                                <span class="date-for-days"
                                  >{{ berita.timestamp_publish_at | date_only }} |
                                  {{ berita.timestamp_publish_at | time_only }}</span
                                >
                              </router-link>
                            </div>
                          </template>
                          <div class="col-md-12 text-center mt-2">
                            <router-link to="/berita">
                              <span
                                class="d-block color-primary trasition-bounce font-weight-6 font-14 cursor-pointer"
                              >
                                Lihat Lainnya
                              </span>
                            </router-link>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </b-nav-item>
            <b-nav-item to="/layanan" @mouseover="onLeave()" exact>
              <b-navbar-brand>
               <div>Layanan</div> </b-navbar-brand>
            </b-nav-item>
            <!-- <b-nav-form>
              <div class="onoffswitch">
                <input
                  type="checkbox"
                  name="onoffswitch"
                  class="onoffswitch-checkbox"
                  id="myonoffswitch"
                  tabindex="0"
                />
                <label class="onoffswitch-label" for="myonoffswitch">
                  <span class="onoffswitch-inner"></span>
                  <span class="onoffswitch-switch"></span>
                </label>
              </div>
            </b-nav-form> -->
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <CoolLightBox
        :items="galleries"
        :index="showedGallery"
        @close="showedGallery = null"
      >
      </CoolLightBox>
    </div>
  </div>
  </div>
  <div id="fixedHeder-res" class="hide-desktop">
    <ResponsiveHEader/>
  </div>
</div>
</template>
<script>
import ResponsiveHEader from "./header-responsive"
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "Header",
  components: {
    CoolLightBox,
    ResponsiveHEader
  },
  data() {
    return {
      NavMega: "",
      publikasiState: 2,
      profilState: 2,
      checkMenuInfo: 1,
      activeClass: false,
      beritaTerbaru: [],
      galleries: [],
      showedGallery: null,
      publicInfo: [],
      importantInfo: [],
      regulationInfo: [],
      subMegamenu:[],
      forSubMega:[],
      menu: [],
      menuInfoPublic: [],
      menuRegulasi: [],
      forSubMegaRegulasi: []
    };
  },
  mounted() {
    this.getGallery();
    this.getBeritaTerbaru(), 
    this.getInformation("public");
    this.getInformation("important");
    this.getInformation("regulation");
    this.getMenu()
    this.getMenuRegulasi()
  },
   created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
     handleScroll () {
       var element =  document.getElementById('fixedHeder');
        var elementres =  document.getElementById('fixedHeder-res');
       if (window.pageYOffset > 0) {
         element.classList.add('style-fixed'); 
         elementres.classList.add('style-fixed'); 
      } else { 
         element.classList.remove('style-fixed');
           elementres.classList.remove('style-fixed'); 
      }
      // Any code to be executed when the window is scrolled
    },
    getMenu() {
      this.axios.get('menu/list/header')
      .then( response => {
        this.menu = response.data.result.menus.filter( menu => menu.id != 100 && menu.id != 101)
        this.menuInfoPublic = response.data.result.menus.find( menu => menu.id == 100)
      })
    },
    getMenuRegulasi() {
      this.axios.get('menu/101/list-child')
      .then( response => {
        this.menuRegulasi = response.data.result.menus
      })
    },
    optionSub(val){
       this.forSubMega = this.subMegamenu[val].chlds
    },
    
    getInformation(category) {
      this.axios
        .get(`information`, {
          params: {
            category: category,
            page: 1,
            limit: 5,
            status: 1,
          },
        })
        .then((response) => {
          if (category == "public") {
            this.publicInfo = response.data.result.informations;
          } else if ((category == "important")) {
            this.importantInfo = response.data.result.informations;
          } else {
            this.regulationInfo = response.data.result.informations;
          }
        });
    },
    redirectTo(info) {
      if (info.type == "redirect") {
        window.open(info.link, "_blank");
      } else if (info.type == "download") {
        window.location = info.file;
      } else {
        this.$router.push(`/information/${info.slug}`);
      }
    },
    getGallery() {
      this.axios
        .get(`gallery`, {
          params: {
            page: 1,
            limit: 10,
            sortBy: "created_at",
            sortType: "DESC",
          },
        })
        .then((response) => {
          this.galleries = response.data.result.galleries.map((item) => {
            const gallery = {
              title: item.title,
              src: item.file,
            };
            if (item.type == "video") {
              gallery.thumb = item.video_thumbnail;
            }

            return gallery;
          });
        });
    },
    getBeritaTerbaru() {
      this.axios
        .get(`news`, {
          params: {
            page: 1,
            limit: 4,
            sortBy: "publish_at",
            sortType: "DESC",
            status: 1,
          },
        })
        .then((response) => {
          this.beritaTerbaru = response.data.result.news;
        });
    },
    onOver(attr) {
      this.NavMega = attr;
    },
    onLeave() { 
      this.NavMega = "";
    },
   
    checkMenuInfoFunc(val) {
      if (val === 1) {
        this.checkMenuInfo = val;
      } else if (val === 2) {
        this.checkMenuInfo = val;
      } else if (val === 3) {
        this.checkMenuInfo = val;
      }
    },

    goTo(val) {
      window.open(val, '_blank');
    },

    checkMenuPub(val) {
      if (val === 1) {
        this.publikasiState = val;
      } else if (val === 2) {
        this.publikasiState = val;
      } else if (val === 3) {
        this.publikasiState = val;
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
    redirectToPost(info) {
      if (info.type == 'redirect') {
        window.open(info.link, '_blank');
      } else if (info.type == 'download') {
        window.open(info.link, '_blank');
      } else {
        this.$router.push(`/post/${info.slug}`)
      }
    },
    redirectToPage(page) {
      if (page) {
        this.$router.push(`/page/${page.slug}`)
      } else {
        this.$router.push('/404')
      }
    },
  },
};
</script>

<style>
  .scrolling {
    max-height: 300px;
    overflow-y: auto;
  }
</style>